import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GcpService {

  private readonly ferme = false;

  isFerme() {
    return this.ferme;
  }
}
