<div  class="banner"></div>
<div class="container encart">
  <h1 class="h1 titre-encart">
    {{"reservation.Titre" | translate}}
  </h1>
  <h2 *ngIf="ferme"> <strong>Le site et l'entreprise sont actuellement fermés, la réouverture est prévue pour le 29 mars 2024. N'hésitez pas à nous appeler directement pour plus d'informations.</strong> </h2>



  <form [formGroup]="reservationForm" id="signupForm" (submit)="onSubmit()" *ngIf="!ferme">
    <div class="form-field-group" *ngIf="prestations$ | async">
      <mat-form-field appearance="outline">
        <mat-label>{{'reservation.DefaultChoice' | translate}}</mat-label>
        <mat-select [formControl]="choixControl" required (selectionChange)="razTotal(); recalculatingPrice(); manageValidators(); recalculatingDate()">
          <mat-select-trigger>{{'prestations.' + choixControl.value | translate}} -
            <span class="price">{{'prestations.' + getLabelPrice(choixControl.value) | translate}}</span>
          </mat-select-trigger>
          <mat-option>{{'reservation.DefaultChoice' | translate}}</mat-option>
          <mat-optgroup *ngFor="let group of groupChoices" [label]="'reservation.' + group.name | translate">
            <mat-option value="{{ choice.titre }}" *ngFor="let choice of group.choices" >
              <p mat-line>{{'prestations.' + choice.titre | translate}} -
                <span class="price">{{'prestations.' + choice.prix | translate}}</span>
              </p>
              <p mat-line class="sub-title" [innerHTML]="'prestations.' + choice.circuit | translate"></p>
            </mat-option>
          </mat-optgroup>
        </mat-select>
        <mat-error *ngIf="choixControl.hasError('required')">Vous devez choisir une option</mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="choixControl.value">
    <label class="label-sam" [innerHTML]="'reservation.nbPersonnes' | translate"></label>
    <br/>
    <div class="row">
      <div class="col-lg-12">
        <div class="col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'reservation.adultes' | translate }}</mat-label>
            <input
              matInput
              inputNumber
              class="input-option-detail"
              [formControl]="adulteControl"
              (keypress)="omitNegativeSign($event);"
              (ngModelChange)="razTotal();recalculatingAdultPrice();"
              (blur)="recalculatingDate();"
              [min]="0" [max]="12" required>
            <mat-error *ngIf="adulteControl.invalid" class="errorMessage">{{getErrorMessage(adulteControl) | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-lg-8 messageInformation">
          <mat-label *ngIf="adulteControl.dirty && adulteControl.hasError('notEnoughSix')">Pour toute réservation pour moins de 6 personnes (de plus de 3 ans), téléphonez au 06.09.06.45.79</mat-label>
          <mat-label *ngIf="adulteControl.dirty && adulteControl.hasError('notEnoughTwo')">Pour toute réservation pour moins de 2 personnes (de plus de 3 ans), téléphonez au 06.09.06.45.79</mat-label>
        </div>
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-lg-12">
        <div class="col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'reservation.enfants' | translate }}</mat-label>
            <input
              matInput inputNumber
              class="input-option-detail"
              [formControl]="enfantControl"
              (ngModelChange)="razTotal(); recalculatingEnfantPrice();"
              (blur)="recalculatingDate();"
              [min]="0"
              (keypress)="omitNegativeSign($event)">
            <mat-error *ngIf="enfantControl.invalid || this.reservationForm.hasError('tooMany','nestedGroup')" class="errorMessage">{{getErrorMessage(enfantControl) | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <br>
    <div class="row">
      <div class="col-lg-12">
        <div class="col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'reservation.enfants3ans' | translate }}</mat-label>
            <input
              matInput inputNumber
              class="input-option-detail"
              [formControl]="enfant3ansControl"
              (ngModelChange)="recalculatingEnfant3ansPrice()"
              (blur)="recalculatingDate();"
              [min]="0"
              (keypress)="omitNegativeSign($event)">
            <mat-error *ngIf="enfant3ansControl.invalid || this.reservationForm.hasError('tooMany','nestedGroup')" class="errorMessage">{{getErrorMessage(enfant3ansControl) | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <br>
    <!-- Heures supplémentaires -->
    <div *ngIf="isHeureSupp()" class="row">
      <div class="col-lg-12">
        <div class="col-lg-4">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'reservation.heuresSupp' | translate }}</mat-label>
            <input class="input-option-detail" matInput inputNumber
                   (keypress)="omitNegativeSign($event)"
                   [formControl]="heuresSuppControl"
                   (ngModelChange)="razTotal(); recalculatingHeuresPrice(); recalculatingDate()"
                   required>
            <mat-error *ngIf="heuresSuppControl.invalid" class="errorMessage">{{getErrorMessage(heuresSuppControl) | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- Sous-total choix et compléments -->
    <div class="row" *ngIf="selection?.reservation">
      <div class="col-sm-11">
        <div class="pull-right">
          <mat-label class="prix-clair"><span class="rouge-cccc"><strong [innerHTML]="'reservation.SubTotalPrestation' | translate"></strong></span>&nbsp;{{priceCalculatedSubTotal()}}&nbsp;€</mat-label>
        </div>
      </div>
    </div>

    <!-- Choix dans la date -->
      <div style="position: relative">
    <mat-card class="encart-choix-date" ngxUiLoaderBlurred [loaderId]="'loaderDate'">
      <label class="label-sam" [innerHTML]="'reservation.ChoixDate' | translate"></label>
      <br/>
      <mat-form-field appearance="outline" class="affichageChoixDate">
        <mat-label>{{ 'reservation.EntrezDate' | translate }}<span class="rouge-cccc">&nbsp;*</span></mat-label>
        <input matInput [matDatepicker]="picker" [formControl]="matDateControl" [min]="minDate" (dateChange)="getDatesDisponibles($event)">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
      <div class="row" *ngIf="selection?.reservation" style="padding:15px;">
        <mat-label class="encartMessageDateErreur" *ngIf="erreurDatesMessage">{{'reservation.ErreurDateMessage' | translate}}</mat-label>
        <mat-label class="encartMessageDateErreur" *ngIf="messageOffice">{{'reservation.MessageOffice' | translate}}</mat-label>
        <mat-label class="encartMessageDateInfo" *ngIf="!erreurDatesMessage && !matDateControl.value">{{'reservation.SelectionDate' | translate}}</mat-label>
        <mat-label class="encartMessageDateInfo" *ngIf="!erreurDatesMessage && matDateControl.value && !dateControl.value && datesChoix.length">{{'reservation.SelectionHeure' | translate}}</mat-label>
        <mat-label class="encartMessageDateInfo" *ngIf="!messageOffice && !erreurDatesMessage && matDateControl.value && !dateControl.value && !datesChoix.length">{{'reservation.PasDisponibilite' | translate}}</mat-label>
        <mat-label class="encartMessageDateInfo" *ngIf="!erreurDatesMessage && matDateControl.value && dateControl.value">{{'reservation.VousAvezChoisi' | translate}} {{matDateControl.value | date: 'dd/MM/YYYY'}}&nbsp;{{'reservation.a' | translate}}&nbsp;{{dateControl.value | date: 'HH:mm'}}</mat-label>
        <ng-container *ngIf="matDateControl.value">
          <mat-selection-list #dates [formControl]="dateControl" [multiple]="false" required>
            <mat-list-option *ngFor="let date of datesChoix" [value]="date" class="mat-list-card">
              {{date | date: 'HH:mm'}}
            </mat-list-option>
          </mat-selection-list>
        </ng-container>
      </div>
      <ngx-ui-loader [loaderId]="'loaderDate'"></ngx-ui-loader>
    </mat-card>

      </div>

    <br>
    <!-- Choix des options -->
    <label class="label-sam" [innerHTML]="'reservation.ChoixOptions' | translate"></label>
      <p *ngIf="selection?.titre === 'TitreExquise'" class="subTitleException">{{ "reservation.ExceptionExquise" | translate }}</p>
    <span *ngIf="options$ | async as options">
      <ul *ngFor="let option of options; let i=index">
        <span class="titre_cb">{{'options.' + option.titre | translate}}</span>
        <li *ngFor="let element of option.elements; let j=index">
          <mat-checkbox [(ngModel)]="element.checked"
                        (ngModelChange)="razTotal();setInitValueCheckbox(optionsControl[i][j], element.checked);priceRecalculatedOptions(options)"
                        [ngModelOptions]="{standalone: true}">
              {{'options.' + element.titre | translate}}
            <span class="prix_cb">&nbsp;{{element.prix}}&nbsp;€&nbsp;</span>
            <span class="description_cb">(&nbsp;{{'options.' + element.description | translate}}&nbsp;)</span>
          </mat-checkbox>
          <div class="row" *ngIf="element.checked">
            <div class="col-sm-10">
              <mat-form-field appearance="outline" class="smallField">
                <mat-label>{{ 'reservation.quantite' | translate }}</mat-label>
                <input
                  matInput
                  inputNumber
                  class="input-option-detail"
                  min="0"
                  (keypress)="omitNegativeSign($event)"
                  (ngModelChange)="razTotal(); priceRecalculatedOptions(options)"
                  [formControl]="optionsControl[i][j]">
                <span matSuffix style="position: relative; bottom: 0.45em; color: darkgray;">{{ 'options.' + option.titre + '_element' | translate}}<span *ngIf="optionsControl[i][j].value > 1">s</span></span>
              </mat-form-field>
            </div>
          </div>
        </li>
      </ul>
    </span>
    <br>
    <!-- Sous-total options -->
    <div class="row">
      <div class="col-sm-11">
        <div class="pull-right">
          <mat-label class="prix-clair"><span class="rouge-cccc"><strong [innerHTML]="'reservation.SubTotalOption' | translate"></strong></span>&nbsp;{{subTotalOptions}}&nbsp;€</mat-label>
        </div>
      </div>
    </div>
    <br>
    <div class="row" *ngIf="selection?.reservation">
      <div class="col-sm-11">
        <div class="pull-right">
          <mat-label class="prix-clair"><span class="rouge-cccc"><strong [innerHTML]="'reservation.Total' | translate"></strong></span>&nbsp;{{total}}&nbsp;€</mat-label>
        </div>
      </div>
    </div>

    <br>
    </div>

    <!-- Soumission du formulaire -->
    <div class="row">
      <div class="col-sm-11">
        <div class="pull-right">
		<!-- remplacer true par isDisabled() -->
          <button mat-raised-button [disabled]="isDisabled()" type="submit" class="btn btn-cccc-color">
												<span matTooltip="{{'error.tooltip' | translate}}" [matTooltipDisabled]="!isDisabled()" *ngIf="!submitted">
              <!--{{selection?.reservation ? 'Payer' : 'Reserver' | translate}}-->
              {{ selection?.reservation ? ('PayerSurPlace' | translate) : ('Reserver' | translate) }}
            </span>
          </button>
          <div *ngIf="selection?.reservation && !paymentActived" class="rouge-cccc">
		  <!--strong>Les réservations sont actuellements indisponibles, réouverture de l'entreprise le 15 avril et les réservations au 1er mai, merci de votre compréhension.</strong-->
            <strong>{{ 'PayerSurPlaceTemporaire' | translate }}</strong>
          </div>
        </div>
      </div>
    </div>
    <!--SANS PAIEMENT-->
    <ng-container *ngIf="!isDisabled() && !!totalForPayment && !paymentActived">
      <app-reservation-sans-paiements
        [totalAPayer]="totalForPayment"
        [totalOption]="subTotalOptions"
        [totalPrestation]="priceCalculatedSubTotal()"
        [reservationDetail]="reservationDetail"></app-reservation-sans-paiements>
    </ng-container>
    <!--PAYMENT-->
    <ng-container *ngIf="!isDisabled() && !!totalForPayment && paymentActived">
      <app-reservation-avec-paiements
        [totalAPayer]="totalForPayment"
        [totalOption]="subTotalOptions"
        [totalPrestation]="priceCalculatedSubTotal()"
        [reservationDetail]="reservationDetail"></app-reservation-avec-paiements>
    </ng-container>
  </form>
</div>
